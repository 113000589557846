@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    input:focus-within ~ label,
    input:not(:placeholder-shown) ~ label,
    textarea:focus-within ~ label,
    textarea:not(:placeholder-shown) ~ label {
        @apply -translate-y-6 scale-75 transform;
    }

    input:focus-within ~ label,
    textarea:focus-within ~ label {
        @apply text-black;
    }

    .outline input:focus-within ~ label,
    .outline input:not(:placeholder-shown) ~ label,
    .outline textarea:focus-within ~ label,
    .outline textarea:not(:placeholder-shown) ~ label {
        @apply z-0 ml-3 -translate-y-4 scale-75 transform px-1 py-0;
    }
}

/* purgecss ignore */
.hamburger {
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    width: 30px;
    height: 24px;
    border: 0;
    margin: 0;
    overflow: visible;
    position: relative;
    top: 1px;
}
.hamburger:hover {
    opacity: 0.7;
}

.hamburger.is-active:hover {
    opacity: 0.7;
}
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
    background-color: currentColor;
}

.hamburger-box {
    width: 30px;
    height: 24px;
    display: inline-block;
    position: relative;
}

.hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
    width: 30px;
    height: 3px;
    border-radius: 3px;
    background-color: currentColor;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
}
.hamburger-inner::before,
.hamburger-inner::after {
    content: '';
    display: block;
}
.hamburger-inner::before {
    top: -10px;
}
.hamburger-inner::after {
    bottom: -10px;
}

.hamburger--spin .hamburger-inner {
    transition-duration: 0.22s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin .hamburger-inner::before {
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}
.hamburger--spin .hamburger-inner::after {
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin.is-active .hamburger-inner {
    transform: rotate(225deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--spin.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}
.hamburger--spin.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.drawn-underline {
    position: relative;
}

.drawn-underline::after {
    content: '';
    height: 1px;
    background: currentColor;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    transform-origin: 0 50%;
    transform: scaleX(0);
    transition: transform 0.4s;
    transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
}

.drawn-underline-bold::after {
    height: 2px;
}

.drawn-underline:hover::after {
    transform: scaleX(1);
}

/* swiper */
.swiper-pagination {
    @apply !bottom-0 !space-x-9;
}

.swiper-pagination-bullet {
    @apply border border-black !bg-transparent;
}

.swiper-pagination-bullet-active {
    @apply !bg-black;
}

.swiper-backface-hidden .swiper-slide,
.swiper-slide,
.swiper-slide li,
.swiper-backface-hidden .swiper-slide li {
    height: 100% !important;
}

/* reCAPTCHA */
.grecaptcha-badge {
    z-index: 10;
}

/* purgecss end ignore */
